import HttpService from './httpService'

export default () => {
  let defaultConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': null,
    }
  }
  return new HttpService(defaultConfig)
}
