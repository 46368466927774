import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';

/* ------------- Redux Configuration ------------- */
const middleware = [];
const enhancers = [];

/* ------------- Saga Middleware ------------- */
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

/* ------------- Assemble Middleware ------------- */
enhancers.push(applyMiddleware(...middleware));

/* ------------- Attach redux dev tool for development environment ------------- */
let composeEnhancers = require('redux-devtools-extension').composeWithDevTools;

/* ------------- Create store ------------- */
const store = createStore(rootReducer, composeEnhancers(...enhancers));

// kickoff root saga
sagaMiddleware.run(rootSaga);

export default { store };
